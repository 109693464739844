import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0050 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="「これって労働時間？」曖昧な時間の取り扱いを再確認！" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.05.12</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        使用者と労働者の永遠のテーマと言っても過言ではない論点に「これは労働時間にあたるのか」という論点があります。労働時間とは端的には
        <span>「使用者の指揮命令下に置かれた時間」</span>
        と解されますが、言うまでもなく抽象的な表現であることから、実務では判断に迷うことが少なくありません。今回は様々な事例を題材として、労働時間にあたるのか否かを解説します。
      </p>
      <img src="/images/column/details/c0050.jpg" alt="キンクラコラム画像50" />
      <h1 id="toc1">ランチミーティング</h1>
      <p>
        課内で上長等を交え、昼食と並行しながら議論をすることを前提とします。無論、上長が在籍すると否とに関わらず、業務に関連した打ち合わせが行われる時間と言えます。昼食を摂りながらとはいえ、休憩時間の自由利用が確保されているとは言い難く、労働時間となるのが一般的です。また、会社で昼食を準備すれば労働時間にはあたらないのではないかとの声もありますが、論点は昼食を誰が用意するという問題ではなく、
        <span>休憩時間が保障されているか</span>という部分です。
      </p>
      <h1 id="toc2">電話番</h1>
      <p>
        場合によってはランチミーティングよりも実際に対応するケースは少ない場合もあるのでしょうが、電話が鳴った場合、昼食中だからと言って、会社の評判を左右すると言っても過言ではない、電話に応対しないというわけにはいきませんので、労働時間となる典型的なケースです。
      </p>

      <h1 id="toc3">夜間の仮眠</h1>
      <p>
        代替要員が確保されており、
        <span>
          緊急時であっても、仮眠が妨げられないというケースを除き労働時間
        </span>
        となります。例えば、2名体制で夜間の対応を求められており、1人が巡回中に仮眠しているもう一方の労働者が緊急時に対応が求められる可能性がある場合、仮眠中であっても実質的に労働からの解放が保障されているとは言えませんので、労働時間となります。
      </p>

      <h1 id="toc4">着替え</h1>
      <p>
        着替えは同僚との談笑と並行して行われることも珍しくなく、度々問題となることがありますが、いわゆるサラリーマンが自宅でスーツに着替える時間は労働時間となることはありません。なぜなら指揮命令が介在していないからです。他方、出勤後に業務上着用が義務付けられている保護具への着替え時間や、指定の場所での着替えを義務付けている場合は労働時間となります。前者との違いとして、
        <span>指揮命令が介在</span>
        しているからです。業務上着用が義務付けられているケースとして安全保護具等が挙げられ、医療従事者等であれば医療安全衛生上の観点から特定の場所での着替えが義務付けられているケースは少なくありません。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc5">準備および後片付け</h1>
      <p>
        業務に関連のない私的な自己研鑽のための準備や後片付けは労働時間とはなりませんが、
        <span>
          業務を遂行するための準備および後片付けは労働時間となります。
        </span>
        他方、能力不足の労働者に限定し、準備および後片付けは労働時間としないとするケースもありますが、その場合、与えている仕事の内容が高度である場合や、当該労働者の能力を見誤っていることもあり、会社側の問題です。そのような労働者に限定して準備および後片付けは労働時間とはならないと切り分けることはできません。
      </p>

      <h1 id="toc6">研修</h1>
      <p>
        業務に関連する研修は言うまでもなく労働時間となります。他方、業務に関連しない私的な自己研鑽のための研修は労働時間ではありません。
      </p>

      <h1 id="toc7">通勤時間</h1>
      <p>
        通勤時間は労働時間ではありません。そもそも「通勤」とは「持参債務」と定義されており、会社が指定する場所で労働を提供するために、労働力を持っていく義務は労働者にありますので、
        <span>通勤時間は労働時間とは扱われません。</span>
        実際に通勤時間中、どのような過ごし方をするのかは自由であることからも理解しやすい論点と考えます。他方、高額な機械や物品を持ち運びながら通勤を命じる場合、目を離すわけにはいきませんので、労働時間と扱われる場合があります。
      </p>

      <h1 id="toc8">出張</h1>
      <p>
        移動中に業務指示を受けず、実際に業務に従事することもなく（移動手段の指示もなく）、自由な利用が保障されている場合は労働時間とはなりません。
        <span>
          他方、通勤時間と同様に、高額な機械や物品を持ち運びながら通勤を命じる場合、通勤時間での例と同様に目を離すわけにはいきませんので、労働時間と扱われる場合があります。
        </span>
        また、通勤時間と異なり、移動時間が長時間に及ぶことが一般的ですので、その間に業務指示を与えている場合は労働時間となります。
        <br />
        <br />
        類似の論点として、出張期間中の休日については業務上の指示がある場合を除き、原則として労働時間とはなりません。
      </p>
      <h1 id="toc9">店舗間の移動等</h1>
      <p>
        <span>
          所定労働時間内に他店舗での業務を目的に移動を命じる場合は労働時間となります。
        </span>
        他方、自宅から現場に直行する場合（機材の監視や他の労働者を乗車させるために会社から指示された場所を経由する場合を除く）や、現場から自宅へ直帰する場合、会社の指示がないにも関わらず現場から会社に立ち寄った場合は労働時間とはなりません。
      </p>
      <h1 id="toc10">最後に</h1>
      <p>
        問題となりやすいケースを限定的に列挙しましたが、働き方の多様化により、今後も労働時間の考え方は複雑となることは明らかです。特に様々な労働時間管理（例えば固定労働時間制だけでなくみなし労働時間制も採用している事業場）を採用している企業の場合は画一的な労務管理ができず、分けて考える必要がありますので、より注意が必要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0050
